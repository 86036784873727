export const FileName: any = {
  master: {
    medicareSupplement: 'Medicare Supplement Master Data.csv',
    medicareAdvantage: 'Medicare Advantage Master Data.csv',
    medicarePrescription: 'Medicare Prescription Master Data.csv'
  }
};

/**
 * CDN Urls of sample templates
 */
export const SampleTemplateCDNUrl = {
  medicareSupplement: {
    popularity:
      process.env.REACT_APP_CLOUDFRONT_URL +
      '/rank-object-sample-templates/Medicare Supplement Popularity Template.csv',
    monthlyPremium:
      process.env.REACT_APP_CLOUDFRONT_URL +
      '/rank-object-sample-templates/Medicare Supplement Monthly Premium Template.csv'
  },
  medicareAdvantage: {
    popularity:
      process.env.REACT_APP_CLOUDFRONT_URL +
      '/rank-object-sample-templates/Medicare Advantage Popularity Template.csv',
    monthlyPremium:
      process.env.REACT_APP_CLOUDFRONT_URL +
      '/rank-object-sample-templates/Medicare Advantage Monthly Premium Template.csv'
  },
  medicarePrescription: {
    popularity:
      process.env.REACT_APP_CLOUDFRONT_URL +
      '/rank-object-sample-templates/Medicare Prescription Popularity Template.csv',
    monthlyPremium:
      process.env.REACT_APP_CLOUDFRONT_URL +
      '/rank-object-sample-templates/Medicare Prescription Monthly Premium Template.csv'
  }
};
